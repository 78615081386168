// extracted by mini-css-extract-plugin
export var section = "faq-module--section--4g+O7";
export var sectionContent = "faq-module--sectionContent--mlfiz";
export var mobile_tabContainer = "faq-module--mobile_tabContainer--iQMdG";
export var selected = "faq-module--selected--IfWuy";
export var desk_tabContainer = "faq-module--desk_tabContainer--B-Rok";
export var title = "faq-module--title--DoSqS";
export var description = "faq-module--description--A3CtZ";
export var row = "faq-module--row--+xEn5";
export var col = "faq-module--col--FnCfy";
export var faqAnswer = "faq-module--faqAnswer--Hoyk0";