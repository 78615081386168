import React, { useRef, useState } from "react";
import * as classes from "../styles/faq.module.scss";
import Layout from "../components/Layout/Layout";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";
import SEO from "../components/Seo";
import { graphql } from "gatsby";
import { MyTab, MyTabs } from "../components/Tabs";
import Typography from "../components/Typography/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  border: "1px solid #ccc",
  marginBottom: "15px !important",
  borderRadius: "16px !important",
  "&.Mui-expanded": {
    background: "#fff",
    color: "#492D21",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary elevation={0} {...props} />
))(() => ({
  padding: "0px 21px 0px 15px",
  "& .MuiAccordionSummary-content": {
    padding: "0 10px",
    margin: "2px 0",
    color: "#492D21",
  },
  "& .MuiAccordionSummary-expandIconWrapper svg": {
    width: "26px !important",
    height: "26px !important",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded svg": {
    color: "#E9E1DE",
    background: "#492D21",
    borderRadius: "50%",
  },
  "& p": {
    fontWeight: 500,
  },
  "&.Mui-expanded p": {
    fontWeight: 500,
    color: "#492D21",
  },
  "&.Mui-expanded": {
    boxShadow: "0px 1px 0px #492d2133",
  },
}));

const AccordionDetails = styled((props) => (
  <MuiAccordionDetails elevation={0} {...props} />
))(() => ({
  "& p": {},
}));

const FaqAccordion = ({ question, answer }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = () => {
    setExpanded((state) => !state);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={expanded ? <RemoveCircleIcon /> : <AddCircleIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography variant="p">{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          className={classes.faqAnswer}
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default function Faq({ data }) {
  const [activeTab, setActiveTab] = useState(0);
  const faqRef = useRef();
  const isMobile = useMediaQuery("(max-width:720px)");
  const activeSectionData = data.allPrismicFa.edges?.[activeTab]?.node.data;
  return (
    <Layout>
      <SEO title="FAQ" />

      {activeSectionData && (
        <div
          ref={faqRef}
          className={classes.section}
          key={activeSectionData.section_title.text}
        >
          <div className={classes.sectionContent}>
            <div>
              {" "}
              <Typography variant="h1" align="center">
                {activeSectionData.section_title.text}
              </Typography>
            </div>

            <Typography variant="h3" align="left" maxWidth>
              {activeSectionData.section_details.text}
            </Typography>

            <div className={classes.desk_tabContainer}>
              <MyTabs
                value={activeTab}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                onChange={(e, v) => {
                  setActiveTab(v);
                }}
              >
                {data.allPrismicFa.edges.map((section, index) => {
                  const title = section.node.data.section_title.text.replace(
                    "FAQ",
                    ""
                  );
                  return (
                    <MyTab key={title + "desk"} value={index} label={title} />
                  );
                })}
              </MyTabs>
            </div>

            <div className={classes.row}>
              {activeSectionData.questions.map((faq) => {
                return (
                  <div key={faq.question.text} className={classes.col}>
                    <FaqAccordion
                      answer={faq.answer.html}
                      question={faq.question.text}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  {
    allPrismicFa {
      edges {
        node {
          data {
            questions {
              answer {
                html
              }
              question {
                text
              }
            }
            section_title {
              text
            }
            section_details {
              text
            }
          }
        }
      }
    }
  }
`;
